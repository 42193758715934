import { store } from "@/redux/store";

export const getSubdomain = () => {
  if (typeof window === "undefined") {
    return null;
  }

  if (process.env.NODE_ENV !== "production") {
    return "remasplaza";
  }

  const host = window.location.host;
  const parts = host.split(".");

  if (parts.length > 2) {
    console.log(parts[0]);
    return parts[0];
  }

  return null;
};

export const currencyFormat = () => {
  return store.getState().language === "ar" ? "ريال" : "SAR";
};
